.container-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000000;
    background-color: transparent;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cube{
    position : relative;
    width : 150px;
    height : 150px;
    transform-style : preserve-3d;
    transform : rotateX(-30deg);
    animation: animate 4s linear infinite;
    z-index: 1000000000;
}

@keyframes animate {
    0% {
        transform: rotateX(-30deg) rotateY(0deg);
    }
    100% {
        transform: rotateX(-30deg) rotateY(360deg);
    }
}

.cube div{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}

.cube div span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#27b7cd , #ffcf3a);
    transform: rotateY(calc(90deg * var(--i))) translateZ(75px);
    animation: wallAni 2s linear infinite;
}

@keyframes wallAni {
    0% {
        transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
    }
    50% {
        transform: rotateY(calc(90deg * var(--i))) translateZ(75px);
    }
    100%{
        transform: rotateY(calc(90deg * var(--i))) translateZ(150px);
    }
}



.top {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background: #27b7cd;
    transform: rotateX(90deg) translateZ(75px);
    animation: topAni 2s linear infinite;
}

@keyframes topAni {
    0% {
        transform: rotateX(90deg) translateZ(150px);
    }

    50% {
        transform: rotateX(90deg) translateZ(75px);
    }

    100% {
        transform: rotateX(90deg) translateZ(150px);
    }
}


.top::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background: #ffcf3a;
    transform: translateZ(-150px);
    filter: blur(20px);
    animation: bottomAni 2s linear infinite;
    box-shadow: 
        0 0 120px rgb(255, 207, 58 , 0.2),
        0 0 200px rgba(255, 207, 58, 0.4),
        0 0 300px rgba(255, 207, 58,0.6),
        0 0 400px rgba(255, 207, 58,0.8),
        0 0 500px rgba(255, 207, 58,1),
}



@keyframes bottomAni {
    0% {
        transform: translateZ(-300px);
    }

    50% {
        transform: translateZ(-150px);
    }

    100% {
        transform: translateZ(-300px);
    }
}